'use client';

import 'src/assets/scss/index.scss';

import BrandCSS from '@sbercloud/figma-tokens-web/build/css/brand.module.css';
import { usePathname } from 'next/navigation';
import { PropsWithChildren, useCallback } from 'react';
import { useEffect } from 'react';
import ReactGtmModule from 'react-gtm-module';
import { ymId } from 'src/global';
import { gtmId } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useRouteChangeEvent } from 'src/hooks/useRouteChangeEvent';
import {
  getYMScript,
  getYMVarioqubScript,
  JIVO_SCRIPT,
  KRAKEN_COUNTER_SCRIPT,
  VK_COUNTER,
} from 'src/scripts';
import { getUtmFromUrl } from 'src/utils/getUtmFromUrl';
import { getUxtFromUrl } from 'src/utils/getUxt';
import { mindboxPageView } from 'src/utils/mindbox';

import { getInitialTheme } from '../contexts/Theme/utils/theme';
import { Providers } from './Providers';

declare global {
  interface Window {
    // Потому что нет достоверного типа от ym
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ym?: (...args: any[]) => void;
  }
}

function Layout({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const { pageViewCustom } = useAnalytics();

  useEffect(() => {
    if (document) {
      const tagManagerArgs = {
        gtmId: gtmId,
        dataLayerName: 'dataLayer',
        dataLayer: {
          originalLocation: document.location.href,
        },
      };

      ReactGtmModule.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    if (window) {
      if (getUtmFromUrl(window.location.href) === '') return;
      window.localStorage.setItem(
        'originalUTM',
        getUtmFromUrl(window.location.href),
      );
    }
  }, []);

  useEffect(() => {
    if (window) {
      if (getUxtFromUrl(window.location.href) === '') return;
      window.localStorage.setItem('uxt', getUxtFromUrl(window.location.href));
    }
  }, []);

  useEffect(() => {
    const mindboxInit = `
        mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
        mindbox.queue = mindbox.queue || [];
        mindbox('create');
        `;
    const mindboxInitFunction = new Function(mindboxInit);
    process.env.NEXT_PUBLIC_SPACE === 'production' && mindboxInitFunction();
  }, []);

  //событие mindbox "просмотр страницы"
  useEffect(() => {
    pathname &&
      process.env.NEXT_PUBLIC_SPACE === 'production' &&
      mindboxPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Ищем таланты: https://cloud.ru/career');
  }, []);

  // ym hit событие pageview для spa сайтов
  const handleRouteChange = useCallback(() => {
    if (window && window.ym) {
      pageViewCustom();
      window.ym(ymId, 'hit', window.location.href, {
        params: {
          title: document.title,
          referer: document.referrer,
        },
      });
    }
  }, [pageViewCustom]);

  // ym hit на 1 заход
  useEffect(() => {
    handleRouteChange();
  }, []);

  // ym hit на все страницы кроме 1 захода
  useRouteChangeEvent(() => {
    handleRouteChange();
  });

  return (
    <html lang="ru" className={getInitialTheme().className}>
      <link rel="manifest" href="/manifest.json" />
      <head>
        <link rel="icon" href="/favicons/logo-48.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/favicons/logo-180.png" />
        <link rel="manifest" href="/manifest.json" />

        {/*  yandex metrika */}
        <script
          dangerouslySetInnerHTML={{
            __html: getYMScript(ymId),
          }}
        />
        {/* Varioqub experiments */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: getYMVarioqubScript(ymId),
          }}
        />

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <div>
                <img
                  src="https://mc.yandex.ru/watch/${ymId}"
                  style={{ position: 'absolute', left: '-9999px' }}
                  alt="yandex"
                  loading="lazy"
                />
              </div>`,
          }}
        />

        {process.env.NEXT_PUBLIC_SPACE === 'production' && (
          <>
            {/* Top100 rambler counter */}
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: KRAKEN_COUNTER_SCRIPT,
              }}
              defer
            />

            {/* Top.Mail.Ru counter (VK) */}
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: VK_COUNTER,
              }}
              defer
            />

            {/* jivo site, lazy load */}
            <script src={JIVO_SCRIPT} defer />

            {/* Top100 rambler counter */}
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<img
                src="//counter.rambler.ru/top100.cnt?pid=7341172"
                style={{ position: 'absolute', left: '-9999px' }}
                alt="Топ-100"
              />`,
              }}
            />

            {/* Top.Mail.Ru counter (VK) */}
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<div>
                <img
                  src="https://top-fwz1.mail.ru/counter?id=3471163;js=na"
                  style={{ position: 'absolute', left: '-9999px' }}
                  alt="Top.Mail.Ru"
                />
              </div>`,
              }}
            />
          </>
        )}
      </head>
      <body>
        {/* https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout */}
        <Providers>{children}</Providers>
        <div id="modals" />
      </body>
    </html>
  );
}

export default Layout;
