import BrandCSS from '@sbercloud/figma-tokens-web/build/css/brand.module.css';
import { Themes } from '@sbercloud/uikit-product-utils';
import cookies from 'js-cookie';

import { COOKIE_DOMAIN } from '../../../global';

// TODO: удалить постфикс _site, когда темная тема будет готова
const STORAGE_KEY_SELECTED_THEME = 'selected-theme_site';

const CLASSNAME_BY_THEME = {
  [Themes.Green]: BrandCSS.light,
  [Themes.GreenDark]: BrandCSS.dark,
};

export const getTheme = (theme: Themes) => {
  return theme === Themes.GreenDark ? Themes.Green : Themes.GreenDark;
};

export const getInitialTheme = (): { theme: Themes; className: string } => {
  const theme =
    (cookies.get(STORAGE_KEY_SELECTED_THEME) as
      | Themes.Green
      | Themes.GreenDark) || Themes.Green;

  return {
    theme,
    className: CLASSNAME_BY_THEME[theme] ?? BrandCSS.light,
  };
};

export const updateTheme = (nextTheme: Themes) => {
  const isLocalhost = window?.location
    .toString()
    .startsWith('http://localhost');

  cookies.set(STORAGE_KEY_SELECTED_THEME, nextTheme, {
    path: '/',
    domain: isLocalhost ? 'localhost' : `.${COOKIE_DOMAIN}`,
    expires: 3650,
  });
};
