import { LanguageCodeType, useConfig } from '@sbercloud/uikit-product-utils';
import { ReactNode } from 'react';

import { useBootstrapTheme } from './hooks/useBootstrapTheme';

export function ThemeProvider({ children }: { children: ReactNode }) {
  const { theme, brand } = useBootstrapTheme();

  useConfig({ theme, languageCode: LanguageCodeType.ruRU, brand });

  return children;
}
