import { Brand, Themes } from '@sbercloud/uikit-product-utils';
import { useEffect, useState } from 'react';
import { tinykeys } from 'tinykeys';

import { getInitialTheme, getTheme, updateTheme } from '../utils/theme';

export function useBootstrapTheme() {
  const [theme, setTheme] = useState<Themes>(() => getInitialTheme().theme);

  useEffect(() => {
    const unsub = tinykeys(window, {
      ['Alt+Shift+KeyT'](event) {
        event.preventDefault();
        const newTheme = getTheme(theme);

        setTheme(newTheme);
        updateTheme(newTheme);
      },
    });

    return () => {
      unsub();
    };
  }, [theme]);

  return { theme, brand: theme.includes('Dark') ? Brand.SiteDark : Brand.Site };
}
