'use client';

import { additionalTranslationsResources } from '@sbercloud/common-translations';
import { LocaleProvider } from '@sbercloud/uikit-product-locale';
import { PropsWithChildren } from 'react';
import { SearchParamsInitializer } from 'src/contexts/SearchParams/SearchParamsInitializer';
import { SearchParamsProvider } from 'src/contexts/SearchParams/SearchParamsProvider';
import { ThemeProvider } from 'src/contexts/Theme';
import { OidcProvider } from 'src/oidc/contexts/oidcProvider';
import { StoreProvider } from 'src/store';

export function Providers({ children }: PropsWithChildren) {
  return (
    <ThemeProvider>
      <SearchParamsProvider>
        <OidcProvider>
          <StoreProvider>
            <LocaleProvider
              lang={'ru-RU'}
              additionalTranslationsResources={additionalTranslationsResources}
            >
              <div style={{ position: 'relative', zIndex: 0 }}>{children}</div>
            </LocaleProvider>
          </StoreProvider>
        </OidcProvider>
        <SearchParamsInitializer />
      </SearchParamsProvider>
    </ThemeProvider>
  );
}
